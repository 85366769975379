<template>
  <div class="container">
    <!--主题公园-->
    <div v-show="tab === 0">
      <img class="full-img" src="~images/hqyc/toubu.png"/>
      <div class="ztgy-list">
        <!--小黄人乐园 TODO: 英文字体的样式以及 TM 特殊字符的样式-->
        <div class="item flexcenter">
          <div class="left" >
            <img class="hqimg" src="~images/hqyc/xhr.png"/>
          </div>
          <div class="right text-box">
            <div class="title">小黄人乐园 <span class="eng">Minion Land</span></div>
            <div class="desc">
              快乐小黄人约起和他们一起玩耍吧！来自照明娱乐的小黄人他们正在尽其所能搅得世界不得安宁！
            </div>
            <div class="desc">你能将它们一一找到吗？</div>
          </div>
        </div>
        <!--哈利波特的魔法世界-->
        <div class="item flexcenter">
          <div class="left text-box">
            <div class="title">哈利波特的魔法世界</div>
            <div class="desc eng">
              The Wizarding Word of Happy Potter
            </div>
            <div class="desc">
              欢迎来到哈利波特的模范世界。踏上巫师村的神奇之旅，探索霍格沃茨   ，魔法学院。
            </div>
          </div>
          <div class="right" >
            <img class="hqimg" src="~images/hqyc/hlbt.png"/>
          </div>
        </div>
        <!--变形金钢基地-->
        <div class="item flexcenter">
          <div class="left" >
            <img class="hqimg" src="~images/hqyc/bxjg.png"/>
          </div>
          <div class="right text-box">
            <div class="title">变形金钢基地</div>
            <div class="desc eng">
              Transformers Metrobase
            </div>
            <div class="desc">
              变形金钢基地是巢穴不对的北京总部，同时也是绝密的超高科技区。在这里，骑车人和人类将携手对抗邪恶的霸天虎。
            </div>
          </div>
        </div>
        <!--侏罗纪世界努布拉岛-->
        <div class="item flexcenter">
          <div class="left text-box">
            <div class="title">侏罗纪世界努布拉岛</div>
            <div class="desc eng">
              Jurassic World Isla Nublar
            </div>
            <div class="desc">
              走进侏罗纪世界努布拉岛。这里的设计灵感源自卖座影片《侏罗纪世界》。快来和巨型恐龙零距离接触，开启你的探险之旅！
            </div>
          </div>
          <div class="right" >
            <img class="hqimg" src="~images/hqyc/zljgy.png"/>
          </div>
        </div>
        <!--功夫熊猫盖世之地-->
        <div class="item flexcenter">
          <div class="left" >
            <img class="hqimg" src="~images/hqyc/gfxm.png"/>
          </div>
          <div class="right text-box">
            <div class="title">功夫熊猫盖世之地</div>
            <div class="desc eng">
              kunsndfljasldfjalsfd
            </div>
            <div class="desc">
              阿宝来啦！快来加入神龙大侠的精彩之旅，体验永不褪色的新奇感受。你讲沉浸在精彩还原的奇幻故事中，准备好了吗？阿宝在功夫熊猫盖世之地迎接你的到来。
            </div>
          </div>
        </div>
        <!--好莱坞-->
        <div class="item flexcenter">
          <div class="left text-box">
            <div class="title">好莱坞 <span class="eng">Hollywood</span></div>
            <div class="desc">
              好莱坞园区将呈现美国洛杉矶的好莱坞大道，让游客仿佛置身好莱坞林荫大道，还将呈现由中美朗达国际导演张艺谋和斯皮尔伯格共同监制打造，将东西电影文化融会贯通。
            </div>
          </div>
          <div class="right" >
            <img class="hqimg" src="~images/hqyc/zljgy.png"/>
          </div>
        </div>
        <!--未来水世界-->
        <div class="item flexcenter">
          <div class="left" >
            <img class="hqimg" src="~images/hqyc/wlssj.png"/>
          </div>
          <div class="right text-box">
            <div class="title">未来水世界 <span class="eng">Waterworld</span></div>
            <div class="desc">
              未来水世界通过逼真的特技演出和高科技特效，完美打造出一个电影中的魔幻世界。
            </div>
          </div>
        </div>
        
        <div class="ztgy-list-bottom">
          <div class="lb1">敬请期待</div>
          <div class="lb2">更多精彩信息</div>
        </div>
      </div>
    </div>
    
    <!--酒店美食-->
    <div v-show="tab === 1">
      <img style="margin-bottom: 49px;" class="full-img" src="~images/hqyc/toubu2.png"/>
      <div class="jdms-list">
        <div class="item">
          <div class="title">环球影城大酒店</div>
          <img class="full-img p20" src="~images/hqyc/hqycdjd.png"/>
          <div class="desc">
            环球影城大酒店是全球首家以环球品牌命名的酒店。酒店设计旨在以现代摩登的方式重新演绎二十世纪初电影文化的辉煌璀璨，建筑外观将采用经典西班牙风格。酒店坐落于北京环球影城主题公园的入口处，拥有大约300间客房，将成为宾客休闲放松的港湾。此外，回溯环球超过一个世纪的电影历史及好莱坞的光辉历程，致敬品牌在光影史上的耕耘与贡献，酒店还运用了大量电影元素，设计了一系列独具巧思的细节与设施，从而打造一段原汁原味的环球主题度假旅程。宾客可以在游乐设施之外，继续身临其境地畅游影视场景，获得不间断不谢幕的沉浸式电影文化新体验。
          </div>
        </div>
        <div class="item">
          <div class="title">诺金度假酒店-北京环球度假区</div>
          <img class="full-img p20" src="~images/hqyc/njdjjd.png"/>
          <div class="desc">
            诺金是中国领先的民族高端酒店品牌之一，诺金度假酒店-北京环球度假区是“诺金”的首家度假酒店。酒店融入中国古典皇家园林的精巧设计，为宾客提供一个风景如画的世外桃源。以中国传统文化和艺术成就为灵感，融合简洁典雅的现代元素，诺金度假酒店将呈现娱乐体验与历史、人文、艺术、前沿科技及可持续理念的完美融合。酒店位于山水环绕、层叠掩映的半岛之上，闹中取静，在主题公园的刺激与欢笑之余，让每一位客人从日复一日的生活中释放身心。诺金度假酒店拥有约400间客房，宾客将于中西方元素的相得益彰之中，充分体验丰富的中华文化传承和优雅的现代感，以及诺金始终如一的中华待客之道。
          </div>
        </div>
        <div class="item">
          <div class="title">北京环球城市大道</div>
          <img class="full-img p20" src="~images/hqyc/hqcsdd.png"/>
          <div class="desc">
            欢乐不止在节假日，更可以在下班之后！美味爆棚的餐厅、乐趣横生的商店，活力四射的演出，欢迎来到北京的新潮流风向标-北京环球城市大道！这里的惊喜，看不尽、玩不够！你大可在这里嗨到深夜，乐至九霄，开启一个缤纷无限的世界！
          </div>
        </div>
      </div>
    </div>
    
    <!--门票预定-->
    <div v-show="tab === 2">
      <img class="full-img" src="~images/hqyc/toubu3.png"/>
      <div class="mpyd-list">
        <div class="top">
          <img class="full-img" src="~images/hqyc/universal.png"/>
          <div class="title">门票预定</div>
        </div>
        <!--TODO: scrolldiv-->
        <!--成人票-->
        <div class="item"
             v-for="(ticket, idx) in ticketList"
             :key="ticket.id"
        >
          <div class="sb flexcenter">
            <div class="title">{{ticket.name}}</div>
            <div>
              <span class="price"><span class="fz24">￥</span>{{ticket.shop_price}}</span>
              <span class="gray">起</span>
            </div>
          </div>
          <div class="sb flexcenter">
            <div>
              <span>限购 {{ticket.max_qty}} 张</span>
              <span class="line m12">|</span>
              <span class="blue">购买须知</span>
            </div>
            <div class="yd" data-id="1" @click="preOrder(ticket.id)">预订</div>
          </div>
        </div>
        <!--青少年票-->
        <div class="item">
          <div class="sb flexcenter">
            <div class="title">青少年票</div>
            <div>
              <span class="gray">?</span>
              <span class="gray huadiao">￥299</span>
              <span class="price"><span class="fz24">￥</span>225</span>
              <span class="gray">起</span>
            </div>
          </div>
          <div class="sb flexcenter">
            <div class="blue">可定明日</div>
            <div class="yj">已减5元</div>
          </div>
          <div class="sb flexcenter">
            <div class="gf">
              <span>官方</span>
              <span class="m12 blue">退换无忧</span>
              <span>无需换票</span>
            </div>
            <div></div>
          </div>
          <div class="sb flexcenter">
            <div>
              <span>已售4000+</span>
              <span class="line m12">|</span>
              <span class="blue">购买须知</span>
            </div>
            <div class="yd" data-id="1" @click="preOrder">预订</div>
          </div>
        </div>
        <!--儿童票-->
        <div class="item">
          <div class="sb flexcenter">
            <div class="title">儿童票</div>
            <div>
              <span class="gray">?</span>
              <span class="gray huadiao">￥299</span>
              <span class="price"><span class="fz24">￥</span>225</span>
              <span class="gray">起</span>
            </div>
          </div>
          <div class="sb flexcenter">
            <div class="blue">可定明日</div>
            <div class="yj">已减5元</div>
          </div>
          <div class="sb flexcenter">
            <div class="gf">
              <span>官方</span>
              <span class="m12 blue">退换无忧</span>
              <span>无需换票</span>
            </div>
            <div></div>
          </div>
          <div class="sb flexcenter">
            <div>
              <span>已售4000+</span>
              <span class="line m12">|</span>
              <span class="blue">购买须知</span>
            </div>
            <div class="yd" data-id="1" @click="preOrder">预订</div>
          </div>
        </div>
        <!--老人票-->
        <div class="item">
          <div class="sb flexcenter">
            <div class="title">老人票</div>
            <div>
              <span class="gray">?</span>
              <span class="gray huadiao">￥299</span>
              <span class="price"><span class="fz24">￥</span>225</span>
              <span class="gray">起</span>
            </div>
          </div>
          <div class="sb flexcenter">
            <div class="blue">可定明日</div>
            <div class="yj">已减5元</div>
          </div>
          <div class="sb flexcenter">
            <div class="gf">
              <span>官方</span>
              <span class="m12 blue">退换无忧</span>
              <span>无需换票</span>
            </div>
            <div></div>
          </div>
          <div class="sb flexcenter">
            <div>
              <span>已售4000+</span>
              <span class="line m12">|</span>
              <span class="blue">购买须知</span>
            </div>
            <div class="yd" data-id="1" @click="preOrder">预订</div>
          </div>
        </div>
      
      </div>
      <div class="mpyd-bottom">
        <div>准备好</div>
        <div>迎接环球的终极体验</div>
      </div>
    </div>
    
    <div class="footer">
      <div :class="['item', {active: tab === 0}]" @click="toggleTab(0)">
        <img class="full-img" src="~images/hqyc/tab1.png"/>
        <div class="desc">主题公园</div>
      </div>
      <div class="border"></div>
      <div :class="['item', {active: tab === 1}]" @click="toggleTab(1)">
        <img class="full-img" src="~images/hqyc/tab2.png"/>
        <div class="desc">酒店美食</div>
      </div>
      <div class="border"></div>
      <div :class="['item', {active: tab === 2}]" @click="toggleTab(2)">
        <img class="full-img" src="~images/hqyc/tab3.png"/>
        <div class="desc">门票预定</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'hqyc',
    data() {
      return {
        tab: 0,
        page: 1,
        totalPage: 0,
        loading:false,
        isopen:0, //是否正式开售
        filter:{}, //搜索条件
        ticketList:[],  //一日游卡
        ticketCardList:[],//年卡等
        silderBarHeight:'',//侧栏高度
      }
    },
    created() {
      this.getTicketList(1,true);
      this.getTicketCardList(1,true);
    },
    methods: {
      //获取一日游门票
      getTicketList(pageNo, override){
        let that = this;
        this.loading = true
        this.post(
          this.API.getTicketList,
          {
            page:pageNo,
            filter:{
              spot_id:1,
              type:1,
              orderby:'sort_order desc'
            }
          }
        ).then(response => {
          let ticketList = response.data.tickets.data;
          
          let {tickets, is_open} = response.data
          this.loading = false
          this.page = tickets.current_page     //当前的页号
          this.totalPage = tickets.last_page  //总页数
          this.isopen = is_open//是否开启
          this.ticketList = override ? ticketList : that.tickets.concat(tickets)
        })
      },
      //获取年卡票
      getTicketCardList(pageNo, override){
        let that = this;
        this.loading = true
        this.post(
          this.API.getTicketList,
          {
            page:pageNo,
            filter:{
              spot_id:1,
              type:2,
              orderby:'sort_order desc'
            }
          }
        ).then(response => {
          let ticketList = response.data.tickets.data;
          this.page = response.data.tickets.current_page     //当前的页号
          this.totalPage = response.data.tickets.last_page  //总页数
          this.ticketCardList = override ? ticketList : that.data.tickets.concat(tickets)
          this.loading = false
        })
      },
      // 切换下方按钮
      toggleTab(tab){
        console.log('tab', tab, this.isopen)
        if(tab == 2 && this.isopen == '0')
        {
          this.$toast('还未开售，尽请期待');
          return;
        }
        this.tab = tab
      },
      //点击预订按钮
      preOrder(tid){
        this.$router.push({
          name: 'mpjs',
          query: {
            tid
          }
        })
      },
      goBackPage:function() {
        this.$router.back(2)
      }
    }
  };
</script>

<style lang="scss" scoped>
  .container {
    background-color: #0658E0;
    min-height: 100%;
    font-size: 32px;
    line-height: 1.6;
    font-family: "PingFang SC";
  }
  
  .hqimg {
    display: block;
    height: 218px;
  }
  .ztgy-list {
    margin-top: -68px;
    padding-bottom: 204px;
  }
  .ztgy-list .item {
    justify-content: space-between;
    margin: 0 24px;
    margin-bottom: 32px;
  }
  .ztgy-list .text-box {
    width: 278px;
    color: #FFFFFF;
  }
  .ztgy-list .text-box .title {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: bold;
  }
  .ztgy-list .text-box .desc {
    font-size: 20px;
    line-height: 30px;
  }
  .ztgy-list .text-box .eng {
    letter-spacing: -4px;
  }
  .ztgy-list-bottom {
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
  }
  .ztgy-list .lb1 {
    font-size: 28px;
  }
  .ztgy-list .lb2 {
    font-size: 36px;
  }
  
  .jdms-list {
    padding: 0 30px;
    padding-bottom: 204px;
    color: #FFFFFF;
  }
  .jdms-list .item {
    margin-bottom: 32px;
  }
  .jdms-list .item .title {
    font-size: 36px;
    font-weight: bold;
  }
  .jdms-list .item .p20 {
    padding: 20px 0;
  }
  .jdms-list .item .desc {
    font-size: 20px;
    text-indent: 50px;
    line-height: 1.7;
  }
  
  .mpyd-list {
    position: relative;
    box-sizing: border-box;
    margin: -56px 30px;
    margin-bottom: 40px;
    padding: 152px 20px 10px;
    border-radius: 20px;
    background-color: #FFFFFF;
  }
  .mpyd-list .top {
    margin: -210px auto 20px;
    width: 228px;
  }
  .mpyd-list .top .title {
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    color: #0658E0;
  }
  .mpyd-list .item {
    margin-bottom: 24px;
    padding: 24px;
    height: 220px;
    background-color: #EEEEEE;
    font-size: 24px;
  }
  .mpyd-list .item .sb{
    justify-content: space-between;
    margin-bottom: 12px;
  }
  .mpyd-list .item .title{
    font-size: 32px;
    font-weight: bold;
    color: #191919;
  }
  .mpyd-list .item .gray{
    color: #777777;
  }
  .mpyd-list .item .huadiao{
    text-decoration: line-through;
  }
  .mpyd-list .item .price{
    font-size: 32px;
    font-weight: bold;
    color: #FF3B33;
  }
  .mpyd-list .item .fz24{
    font-size: 24px;
  }
  .mpyd-list .item .blue{
    color: #0658E0;
  }
  .mpyd-list .item .yj{
    color: #F84F41;
  }
  .mpyd-list .item .gf{
    color: #191919;
  }
  .mpyd-list .item .m12{
    margin: 0 12px;
  }
  .mpyd-list .item .line {
    color: #C7C7C7;
  }
  .mpyd-list .item .yd{
    width: 130px;
    height: 52px;
    line-height: 52px;
    background-color: #F84F41;
    text-align: center;
    border-radius: 26px;
    color: #FFFFFF;
    font-size: 28px;
    font-weight: bold;
  }
  .mpyd-bottom {
    margin-bottom: 200px;
    text-align: center;
    color: #FFFFFF;
    font-size: 36px;
    font-weight: bold;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 140px;
    box-sizing: border-box;
    padding: 0 83px;
    background: #FFFFFF;
  }
  .footer .border {
    margin-top: 19px;
    width: 2px;
    height: 88px;
    background-color: #DCDDDD;
  }
  .footer .item {
    margin-top: -44px;
    width: 124px;
    font-size: 24px;
    color: #777777;
    font-weight: bold;
    text-align: center;
  }
  .footer .desc {
    margin-top: 8px;
  }
  .footer .active .desc {
    color: #0658E0;
  }

</style>
